import React, { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { AuthUser, defaultAuthUser } from '../../models/Auth';
import { FunctionComponentWithChildren } from '../../models/React';
import { IUser } from '../../models/User';
import axiosInstance from '../../utils/axiosInstance';
import { getLocalStorageToken, tokenKeyLocalStorage } from '../../utils/flavourstorage';
import { useConfig } from '../ConfigContext';
import { CtxProvider } from './AuthUserContext';

export const AuthUserProvider: FunctionComponentWithChildren = ({ children }) => {
    const [authUser, setAuthUser] = useState<AuthUser>(defaultAuthUser);
    const user = authUser.user;

    const setUser = (user: IUser) => {
        setAuthUser({ ...authUser, user });
    };

    const runOnce = useRef(true);

    const { t } = useTranslation();
    const { config } = useConfig();

    useEffect(() => {
        if (runOnce.current) {
            runOnce.current = false;
            checkSession();
        }
    }, []);

    const checkSession = useCallback(() => {
        const token = getLocalStorageToken();

        if (!token) {
            setAuthUser({ authChecked: true, isLoggedIn: false, user: undefined });
        } else {
            autoLogin(token);
        }
    }, []);

    const autoLogin = (token: string) => {
        // Set headers with user access token
        axiosInstance.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
        };

        axiosInstance
            .get<IUser>(`${config.baseUrl}/user`)
            .then((response) => {
                setAuthUser({ authChecked: true, isLoggedIn: true, user: response.data });
            })
            .catch((error) => {
                if (error.status === 403) {
                    setAuthUser({ authChecked: true, isLoggedIn: true });
                } else {
                    logout();
                }
            });
    };

    const logout = async () => {
        localStorage.removeItem(tokenKeyLocalStorage);
        setAuthUser({ authChecked: true, isLoggedIn: false });
        await axiosInstance.post(`${config.baseUrl}/logout`, {});

        toast.success(t('Common:TOAST_LOGOUT_SUCCESS'));
    };

    const onSignOutClick = async (callback?: () => void) => {
        logout();
        callback?.();
    };

    return (
        <CtxProvider
            value={{
                user: user,
                auth: authUser,
                setUser,
                setAuthUser,
                checkSession,
                autoLogin,
                logout,
                onSignOutClick,
            }}
        >
            {authUser.authChecked && children}
        </CtxProvider>
    );
};
