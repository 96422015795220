import { getSubdomain } from './domain';

export const tokenKeyLocalStorage = `${getSubdomain()}_token`;

export const setLocalStorageToken = (token: string): void => {
    localStorage.setItem(tokenKeyLocalStorage, token);
};

export const getLocalStorageToken = (): string | null => {
    return localStorage.getItem(tokenKeyLocalStorage);
};
