import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAuthUser } from '../../context/AuthUser/AuthUserContext';
import { useConfig } from '../../context/ConfigContext';
import LoginForm from './LoginForm/LoginForm';

export interface LoginData {
    forceLogout: boolean;
}
export interface LoginLocation {
    data?: LoginData;
}

const Login: FunctionComponent = () => {
    const { config } = useConfig();
    const { checkSession, logout: AuthLogout } = useAuthUser();
    const location = useLocation();
    const [loginData, setLoginData] = useState<LoginData | undefined>();

    useEffect(() => {
        checkSession();
    }, [checkSession]);

    useEffect(() => {
        const locationState = location.state as LoginLocation;
        if (locationState?.data) {
            setLoginData(locationState.data);
        }
    }, [location]);

    useEffect(() => {
        if (loginData && loginData.forceLogout) {
            AuthLogout();
        }
    }, [loginData, config.baseUrl]);

    return <LoginForm></LoginForm>;
};

export default Login;
